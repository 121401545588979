.pwdChangePopup {
    width: 1200px;
    height: 700px;
    background: white;
}

.pwdChangeIframe {
    background: white;
    width: 1200px;
    height: 700px;
}