.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.paginationPosition {
    position: absolute;
    bottom: 0;
    justify-content: space-between;
    width: 100%;
}

/*
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
*/

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
body {
    /* font-family: "Poppins", sans-serif; */
    font-family: "Noto Sans KR", sans-serif;
    background: #fafafa;
    font-size: .875rem;
}

.form-control {
    -webkit-appearance: auto !important;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}


/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */

.sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #3b4b64;
    color: #fff;
    margin-left: -250px;
    transition: all 0.5s;
}

.sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
}

.sidebar-header {
    background: #303c54;
}

.sidebar-header h3 {
    color: #fff;
    padding: 1em;
}

.sidebar ul p {
    color: #fff;
    padding: 10px;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #8f96a9;
    text-decoration: none;
}

.navbar-inverse.navbar-nav>li>a:hover {
    background: grey;
}

.menu-open {
    background: #6d7fcc;
}

.nav-item:hover {
    color: #fff;
    background: #46556c;
}

.nav-item:active {
    color: #fff;
    background: #46556c;
}

.items-menu {
    color: #fff;
    background: #6d7fcc;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
}

.sidebar-header>span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}

.side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
    width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
    background: #5466b3;
    border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
    background: #3a4c99;
}


/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */

.content {
    margin-left: 0;
    height: 100vh;
    max-height: 1024px;
}

@media only screen and (max-width: 500px) {
    body {
        overflow: hidden;
    }
    .content.is-open {
        margin-left: 100%;
    }
    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.5s, height 0s;
    }
    .sidebar.is-open>.sidebar-header span {
        display: unset;
    }
    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}

.alertPopup {
    width: 350px;
    height: 173px;
    max-width: 900px;
    padding: 10px;
    position: absolute;
    top: 100px;
    left: calc(50% - 450px);
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 520px;
    background: white;
    border: 1px solid black;
    z-index: 9;
}

.bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.pointer {
    cursor: pointer;
}

.progressAlert {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
}

.progressAlert > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.loadingSpinner {
    vertical-align: middle;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #3a4c99;
    border-top-color: #fff;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}