.search_box {
    width: 150px;
    font-size: 12px;
    margin-right: 10px;
}

.date_picker_div {
    /* float: left; 
    padding-top: 3px;
    width: 80%;
    */
    padding-right: 10px;
    vertical-align: center;
}

.react-datepicker-wrapper {
    width: 150px !important;
}


/* .date_picker {
    width: 100px;
    font-size: 12px;
} */

.search_box.xmark {
    width: 130px;
}

.extensionCalls-toggle,
.RefreshOption-toggle {
    background-color: #c3c3c3;
    /* padding: 2px; */
    border-radius: .25rem;
    display: inline-block;
    width: 90px;
    /* height: 20px; */
    display: flex;
    /* justify-content: space-around; */
}
.extensionCalls-toggle [name="kind_yn"],
.RefreshOption-toggle [name="refreshOption"] {
    display: none;
}
  
.extensionCalls-toggle input[type="radio"]+label,
.RefreshOption-toggle input[type="radio"]+label {
    font-size: 14px;
    background-color: #c3c3c3;
    color: #212529;
    width: 100%;
    text-align: center;
    line-height: 35.5px;
    border-radius: .25rem;
    cursor: pointer;
    user-select: none;
    font-weight: 300;
}
.extensionCalls-toggle input[type="radio"]+label[for="includeExtensionCalls"],
.RefreshOption-toggle input[type="radio"]+label[for="onRefreshOption"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.extensionCalls-toggle input[type="radio"]+label[for="withoutExtensionCalls"],
.RefreshOption-toggle input[type="radio"]+label[for="offRefreshOption"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.extensionCalls-toggle input[type="radio"]:checked+label,
.RefreshOption-toggle input[type="radio"]:checked+label {
    background-color: #5c636a;
    color: #fff;
    font-weight: 400;
}