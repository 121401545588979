table.corpTable,
table.lineTable,
table.recordTable,
table.smsTable,
table.admTable,
table.agentTable,
table.apiTable,
table.callbackTable,
table.logTable,
table.errlogTable,
table.statcorpTable,
table.authcentrixTable {
  width: calc(100% - 10px) !important;
  height: calc(100% - 180px);
  /* height: calc(100% - 200px); */
}
.corpTable thead tr,
.lineTable thead tr,
.recordTable thead tr,
.smsTable thead tr,
.admTable thead tr,
.agentTable thead tr,
.apiTable thead tr,
.callbackTable thead tr,
.logTable thead tr,
.errlogTable thead tr,
.statcorpTable thead tr,
.authcentrixTable thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.corpTable tbody,
.lineTable tbody,
.recordTable tbody,
.smsTable tbody,
.admTable tbody,
.agentTable tbody,
.apiTable tbody,
.callbackTable tbody,
.logTable tbody,
.errlogTable tbody,
.statcorpTable tbody,
.authcentrixTable tbody {
  display: block;
  width: calc(100% + 10px);
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.corpTable tbody tr,
.lineTable tbody tr,
.recordTable tbody tr,
.smsTable tbody tr,
.admTable tbody tr,
.agentTable tbody tr,
.apiTable tbody tr,
.callbackTable tbody tr,
.logTable tbody tr,
.errlogTable tbody tr,
.statcorpTable tbody tr,
.authcentrixTable tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
th ,td {
  text-align: center;
}

.authcentrixTable tbody tr .btn-success {
  box-shadow: none !important;
  width: 70px;
  height: 24px;
  line-height: 16px;
}
.authcentrixTable tbody tr .btn-outline-danger,
.authcentrixTable tbody tr .btn-danger:disabled {
  width: 70px;
  height: 24px;
  line-height: 16px;
}
.authcentrixTable tbody tr .spinner-border {
  width: 10px;
  height: 10px;
  vertical-align: unset;
}