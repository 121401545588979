@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.form-main {
    margin-top: 100px;
}

.form-signin {
    margin-bottom: 2px;
    width: 350px;
    height: 300ps;
    margin: 0 auto;
}

.form-control {
    margin-bottom: 2px;
}
.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.paginationPosition {
    position: absolute;
    bottom: 0;
    justify-content: space-between;
    width: 100%;
}

/*
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
*/
body {
    /* font-family: "Poppins", sans-serif; */
    font-family: "Noto Sans KR", sans-serif;
    background: #fafafa;
    font-size: .875rem;
}

.form-control {
    -webkit-appearance: auto !important;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}


/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */

.sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #3b4b64;
    color: #fff;
    margin-left: -250px;
    transition: all 0.5s;
}

.sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
}

.sidebar-header {
    background: #303c54;
}

.sidebar-header h3 {
    color: #fff;
    padding: 1em;
}

.sidebar ul p {
    color: #fff;
    padding: 10px;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #8f96a9;
    text-decoration: none;
}

.navbar-inverse.navbar-nav>li>a:hover {
    background: grey;
}

.menu-open {
    background: #6d7fcc;
}

.nav-item:hover {
    color: #fff;
    background: #46556c;
}

.nav-item:active {
    color: #fff;
    background: #46556c;
}

.items-menu {
    color: #fff;
    background: #6d7fcc;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
}

.sidebar-header>span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}

.side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
    width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
    background: #5466b3;
    border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
    background: #3a4c99;
}


/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */

.content {
    margin-left: 0;
    height: 100vh;
    max-height: 1024px;
}

@media only screen and (max-width: 500px) {
    body {
        overflow: hidden;
    }
    .content.is-open {
        margin-left: 100%;
    }
    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.5s, height 0s;
    }
    .sidebar.is-open>.sidebar-header span {
        display: unset;
    }
    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}

.alertPopup {
    width: 350px;
    height: 173px;
    max-width: 900px;
    padding: 10px;
    position: absolute;
    top: 100px;
    left: calc(50% - 450px);
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 500px;
    height: 520px;
    background: white;
    border: 1px solid black;
    z-index: 9;
}

.bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.pointer {
    cursor: pointer;
}

.progressAlert {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
}

.progressAlert > div {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #fff;
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.loadingSpinner {
    vertical-align: middle;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #3a4c99;
    border-top-color: #fff;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
table.corpTable,
table.lineTable,
table.recordTable,
table.smsTable,
table.admTable,
table.agentTable,
table.apiTable,
table.callbackTable,
table.logTable,
table.errlogTable,
table.statcorpTable,
table.authcentrixTable {
  width: calc(100% - 10px) !important;
  height: calc(100% - 180px);
  /* height: calc(100% - 200px); */
}
.corpTable thead tr,
.lineTable thead tr,
.recordTable thead tr,
.smsTable thead tr,
.admTable thead tr,
.agentTable thead tr,
.apiTable thead tr,
.callbackTable thead tr,
.logTable thead tr,
.errlogTable thead tr,
.statcorpTable thead tr,
.authcentrixTable thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.corpTable tbody,
.lineTable tbody,
.recordTable tbody,
.smsTable tbody,
.admTable tbody,
.agentTable tbody,
.apiTable tbody,
.callbackTable tbody,
.logTable tbody,
.errlogTable tbody,
.statcorpTable tbody,
.authcentrixTable tbody {
  display: block;
  width: calc(100% + 10px);
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.corpTable tbody tr,
.lineTable tbody tr,
.recordTable tbody tr,
.smsTable tbody tr,
.admTable tbody tr,
.agentTable tbody tr,
.apiTable tbody tr,
.callbackTable tbody tr,
.logTable tbody tr,
.errlogTable tbody tr,
.statcorpTable tbody tr,
.authcentrixTable tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
th ,td {
  text-align: center;
}

.authcentrixTable tbody tr .btn-success {
  box-shadow: none !important;
  width: 70px;
  height: 24px;
  line-height: 16px;
}
.authcentrixTable tbody tr .btn-outline-danger,
.authcentrixTable tbody tr .btn-danger:disabled {
  width: 70px;
  height: 24px;
  line-height: 16px;
}
.authcentrixTable tbody tr .spinner-border {
  width: 10px;
  height: 10px;
  vertical-align: unset;
}
.search_box {
    width: 150px;
    font-size: 12px;
    margin-right: 10px;
}

.date_picker_div {
    /* float: left; 
    padding-top: 3px;
    width: 80%;
    */
    padding-right: 10px;
    vertical-align: center;
}

.react-datepicker-wrapper {
    width: 150px !important;
}


/* .date_picker {
    width: 100px;
    font-size: 12px;
} */

.search_box.xmark {
    width: 130px;
}

.extensionCalls-toggle,
.RefreshOption-toggle {
    background-color: #c3c3c3;
    /* padding: 2px; */
    border-radius: .25rem;
    display: inline-block;
    width: 90px;
    /* height: 20px; */
    display: flex;
    /* justify-content: space-around; */
}
.extensionCalls-toggle [name="kind_yn"],
.RefreshOption-toggle [name="refreshOption"] {
    display: none;
}
  
.extensionCalls-toggle input[type="radio"]+label,
.RefreshOption-toggle input[type="radio"]+label {
    font-size: 14px;
    background-color: #c3c3c3;
    color: #212529;
    width: 100%;
    text-align: center;
    line-height: 35.5px;
    border-radius: .25rem;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-weight: 300;
}
.extensionCalls-toggle input[type="radio"]+label[for="includeExtensionCalls"],
.RefreshOption-toggle input[type="radio"]+label[for="onRefreshOption"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.extensionCalls-toggle input[type="radio"]+label[for="withoutExtensionCalls"],
.RefreshOption-toggle input[type="radio"]+label[for="offRefreshOption"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.extensionCalls-toggle input[type="radio"]:checked+label,
.RefreshOption-toggle input[type="radio"]:checked+label {
    background-color: #5c636a;
    color: #fff;
    font-weight: 400;
}
/* .page-item {
} */

.page-link{
  color: rgba(var(--bs-dark-rgb), 1);
}

.page-item.active .page-link {
  background: rgba(var(--bs-dark-rgb), 1);
  border-color: rgba(var(--bs-dark-rgb), 1);
}

.table>:not(caption)>*>* {
  padding: 5px;
}
.pwdChangePopup {
    width: 1200px;
    height: 700px;
    background: white;
}

.pwdChangeIframe {
    background: white;
    width: 1200px;
    height: 700px;
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.refresh-rotate {
  transition: all 0.5s;
  -webkit-animation: rotate 0.5s linear infinite;
          animation: rotate 0.5s linear infinite;
}
