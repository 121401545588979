.form-main {
    margin-top: 100px;
}

.form-signin {
    margin-bottom: 2px;
    width: 350px;
    height: 300ps;
    margin: 0 auto;
}

.form-control {
    margin-bottom: 2px;
}