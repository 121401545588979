/* .page-item {
} */

.page-link{
  color: rgba(var(--bs-dark-rgb), 1);
}

.page-item.active .page-link {
  background: rgba(var(--bs-dark-rgb), 1);
  border-color: rgba(var(--bs-dark-rgb), 1);
}
